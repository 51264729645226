const React = require("react");

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  window.scrollTo(...(currentPosition || [0, 0]))
}


// // before url change
// exports.onPreRouteUpdate = ({ location, prevLocation }) => {
//   // ScrollTrigger.kill();
// }